.content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
}

.formulario {
    flex-direction: column;
    justify-content: center;
    max-width: 400px;
}

.customSelect {
     :global {
        .ant-select-selection {
            width: 50px;
            background-color: olive;
        }
    }
}

.item {
    width: 100%;
    height: 30px;
    margin-bottom: 20px;
}

:host .atn-row {
    display: none;
}

.proximo {
    display: flex;
   height: 40px;
    color: white;
    font-size: 15px;
}

.salvar{
    display: flex;

    height: 40px;
    color: white;
    font-size: 15px;
}

.buttonsArea {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
}

.groupItem {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.ant-row .ant-form-item {
    flex-flow: column;
    flex-direction: column;
}

.flex {
    display: flex;
}

.flex-direction {
    flex-direction: row;
}

.justifyContentSpaceBetween {
    justify-content: space-between;
}

.TabHeader:hover{
    cursor: context-menu;
}