:global{
    .ant-upload.ant-upload-select{
        background-color:white;
    }
    .ant-popover-message{
        font-size: 13px;
    }
    
    @media (max-width:540px){

        .ant-btn-background-ghost.ant-btn-primary{
            
            color: #1890ff;
            border-color: #1890ff;
            text-shadow: none;
            width: 153px;
            font-size: 80%;
            padding: 1px;
        }
        
    }

    @media (max-width:500px){
        .ant-btn-background-ghost.ant-btn-primary{
            width: 125px;
            font-size: 70%;
            padding: 1px;
        }
    }

    @media (max-width:330px){
        .ant-btn-background-ghost.ant-btn-primary{
            width: 115px;
            font-size: 60%;
            padding: 1px;
        }
    }
}


@select-border-color: red;@select-dropdown-bg: blue;@input-disabled-color: black;