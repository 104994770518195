.footer {
    display: flex;
    background-color: #004F9F;
    flex-direction: row;
    width: 100%;
    height: 60px;
    bottom: 0;
    left: 0;
    z-index: 20;
    justify-content: space-between;
    padding: 0 40px;
}

.imgs {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    margin-right: 15px;
    padding: 10px;
    align-items: center;
}

.img1 .img2 .img3 {
    align-items: center;
}

.img1 {
    margin-left: 10px;
    width: 80px;
    height: 16px;
}

.img2 {
    width: 80px;
    margin-left: 10px;
}

.img3 {
    width: 100px;
    margin-left: 15px;
}

@media (max-width: 700px) {
    .footer {
        position: fixed;
        z-index: 100;
    }
}

.img  {
    width: 60px;
}