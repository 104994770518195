.CadastroFormulario {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Cadastro {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
}

.Titulo {
    font-size: 2em;
    font-weight: bold;
    color: #009696;
    display: flex;
    justify-content: center;
    margin-bottom: 0px;
}

.Inputs {
    width: 60vw;
    max-width: 500px;
    padding: 0.75em 0.75em;
    background: #fff;
    border: 1px solid #009696;
    font-weight: 600;
    font-size: 1em;
    -moz-border-radius: 0.75em;
    -webkit-border-radius: 0.75em;
    border-radius: 0.75em;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -o-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
}

.InputsPassword>input {
    border: 0px;
    display: flex;
}

.Inputs::placeholder {
    color: #b1b1b1;
}

.Inputs>input[type="password"]::placeholder {
    color: #b1b1b1;
    font-weight: bold;
}

.InputsPassword {
    display: flex;
    margin: 5px 0px 5px 0px;
    padding: 0px;
    border: 1px solid #009696;
    justify-content: space-between;
    border-radius: 5px;
    width: 220px;
    padding: 2px 0px 2px 4px;
}

.FormName {
    display: flex;
    flex-direction: column;
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 1vw;
}

.ButtonCadastro {
    background-color: #038ebf;
    border: 0px;
    color: #fff;
    padding: 0 15px;
    margin: 0;
    width: 100px;
}

.ButtonVoltar {
    background-color: #63a42a;
    border: 0px;
    color: #fff;
    padding: 0px 15px;
    width: 100px;
}

.ButtonVoltar:hover {
    background-color: #fff;
    color: #63a42a;
}

.Buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    font-weight: bold;
    align-items: center;
    margin-bottom: 1%;
}

.Buttons>div {
    margin: 0;
}

.Checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 7px;
    font-weight: bold;
    margin-bottom: 5%;
}

.FormName>div {
    padding: 0 !important;
}

.TooltipCadastro {
    max-width: 50vw;
}

.reenvio {
    display: flex;
    justify-content: center;
    margin-top: 1em;
    margin-bottom: 1.2em;
}

.checkboxAceite {
    display: flex;
    justify-content: center;
}