%item-global {
    width: 100%;
    height: 30px;
    background: #fff;
    border: 1px solid #009696;
    border-radius: .75em;
    box-shadow: none;
    font-size: 1em;
    font-weight: 600;
    padding: .75em;
    transition: all .3s;
}

.tab {
    margin-top: 0px;
}

.formulario {
    flex-direction: column;
    justify-content: center;
    max-width: 500px;
    min-width: 300px;
    width: 70vw;
}

.item {
    @extend %item-global;
}

.itemError {
    @extend %item-global;
    border: 1px solid #ff4d4f;
}

:host .atn-row {
    display: none;
}

.proximo {
    align-items: center;
    display: flex;
    height: 40px;
    color: white;
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 15px;
}

.finalizar {
    align-items: center;
    display: flex;
    height: 40px;
    margin: 3px;
    color: white;
    font-size: 15px;
    margin-top: 5px;
}

.salvar {
    align-items: center;
    display: flex;
    height: 40px;
    color: white;
    font-size: 15px;
    margin-top: 5px;
}

.buttonsArea {
    display: flex;
    justify-content: space-between;
}

.buttonsAreaDados {
    display: flex;
    justify-content: flex-end;
}

.groupItem {
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 25px;
    margin-top: none;
}

.groupItem2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 25px;
    margin-top: none;
}

.ant-row .ant-form-item {
    flex-flow: column;
    flex-direction: column;
}

.flex {
    display: flex;
}

.flex-direction {
    flex-direction: row;
}

.justifyContentSpaceBetween {
    justify-content: space-between;
}

.alignItemsCenter {
    align-items: center;
}

.justifyContentCenter {
    justify-content: center;
}

.inputCpfDisabled {
    width: 100%;
    height: 30px;
    background: #fff;
    border-radius: .75em;
    box-shadow: none;
    font-size: 1em;
    font-weight: 600;
    padding: .70em;
    color: #00000040;
    transition: all .3s;
    cursor: not-allowed;
}

.tituloResponsavel {
    font-size: 1.3em;
    margin-bottom: 0;
}


/*Styles ModalEdital*/

.modal {
    min-width: 800px;
    width: 100%;
    max-width: 800px;
    min-height: 700px;
    height: 700px;
    max-height: 800px;
}

.pdfContainer {
    height: 100%;
    max-height: 600px;
    overflow: scroll;
}

.buttonsAceite {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    padding-right: 5px;
    padding-left: 5px;
}


/*Fim Styles ModalEdital*/

.Tooltip{
    width: 26%;
    max-width: 320px;
    min-width: 130px;
}

@media (max-width:600px) {
    html,
    body {
        overflow-x: hidden;
        position: relative;
    }
}