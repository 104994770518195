.Buttons {
    text-align: center;
    margin: 1vh;
}

body{
    background: #e5e5e5 !important;
}
.Preview {
    width: 100%;
}

.Card {
    width: 100%;
    max-width: 400px;
    margin: 3px;
}

.CardPdf {
    width: 100%;
    margin: 3px;
}

.ButtonVotar {
    border: solid 1px #191d35 !important;
    background-color: #191d35 !important;
    margin-top: 10px;
    color: white !important;
}

.ButtonVotar:focus {
    border: solid 1px #191d35;
    background-color: #191d35;
    margin-top: 10px;
    color: white;
}

.ButtonVotar:hover {
    border: solid 1px #191d35 !important;
    background-color: #a7a8ac !important;
    margin-top: 10px;
    color: #191d35 !important;
}

.InfoDesenho {
    font-size: 75%;
    text-align: center;
    margin: 10px;
}

.CardBody {
    margin: -25px 0 0 0;
    display: block;
}

.Titulo {
    font-size: 2em;
    font-weight: bold;
    color: #009696;
    display: flex;
    justify-content: center;
    margin-bottom: 0px;
}
.fundo{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-image: url('../../../assets/agilita/backgorund_mascote.svg');
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: 100% 0%;

}