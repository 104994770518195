.emailConfirmadoDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: #009696;
    width: 100%;
    margin: 20px;
    padding: 20px;
}

.emailConfirmadoDiv a {
    color: #009696;
    font-family: BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    font-size: 15px;
    text-decoration: underline;
}

.emailConfirmadoDiv h3 {
    font-size: large;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: center;
}

.emailConfirmadoDivError {
    color: #ce4a4a !important;
}

.input {
    width: 100%;
    padding: 1em;
    background: #fff;
    border: 1px solid #009696;
    font-weight: 600;
    font-size: 1em;
    -moz-border-radius: 0.75em;
    -webkit-border-radius: 0.75em;
    border-radius: 0.75em;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -o-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
    font-size: 20px;
    height: 1em;
}

.FormName {
    display: flex;
    flex-direction: column;
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 1em;
}

.container {
    display: flex;
    justify-content: center;
    margin: 10px
}

.btn {
    border-radius: 5px;
    height: 3em;
}

@media (max-width:400px) {
    .emailConfirmadoDiv h3 {
        font-size: large;
        font-weight: bold;
        margin-bottom: 20px;
        text-align: center;
    }
}