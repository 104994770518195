.card {
    display: grid;
    width: 70%;
    grid-template-rows: 70% 30%;
    height: 191px;
    background-color: rgb(0, 150, 150, 80%);
    margin: 10px;
}

.txt {
    text-align: left;
    padding-top: 5px;
    padding-left: 5px;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
}

.img {
    width: 80%;
    max-height: 133.69px;
    // height: 100%;
}

.bottom {
    width: 100%;
    background-color: rgb(0, 150, 150, 80%);
    padding-top: 5px;
    // border: 1px solid rgb(0, 150, 150,80%);
}

.desc {
    box-sizing: border-box;
    word-break: break-all;
}

.btn {
    border: none;
    background: rgb(239, 239, 239);
}