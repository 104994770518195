        .Titulo {
            font-size: 2em;
            font-weight: bold;
            color: #009696;
            display: flex;
            justify-content: center;
            margin-bottom: 35px;
            text-align: center;
        }
        
        .container {
            margin: 15px 0px 15px 0px;
        }
        
        .assinatura {
            width: 47%;
            padding-left: 6%;
        }
        
        .assinatura2 {
            width: 63%;
            margin-left: 10px;
            padding-right: 6%;
        }
        
        .assinaturaBox {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        
        .Centro {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 30%;
            font-size: 1.2em;
        }
        
        .ButtonDownload {
            background-color: rgb(255 255 255 / 0%);
            color: #52c41a;
            font-weight: 400;
            border: none;
            border-radius: 5px;
            text-align: center;
            transition: all 0.75s;
            cursor: pointer;
            height: 3.5em;
            margin: 5px;
        }
        
        .IconDownload {
            font-size: 1.5em;
            padding-left: 0.5em;
        }
        
        .footer {
            margin-right: 20%;
        }
        
        .logo {
            width: 30%;
            margin-left: 25%;
        }
        
        .bg {
            // height: 100%; /* You must set a specified height */
            background-image: url("../../../assets/BgImageProtocol.png");
            background-repeat: no-repeat;
            /* Do not repeat the image */
            background-size: contain;
            /* Resize the background image to cover the entire container */
            display: flex;
            flex-direction: column;
            background-position: center center;
            align-items: center;
            justify-content: center;
        }
        
        .content {
            padding: 15% 28% 0% 28%;
            margin-bottom: 4em;
        }
        
        .button {
            display: flex;
            justify-content: center;
        }
        
        .header {
            display: flex;
            justify-content: space-between;
        }
        
        @media (max-width: 820px) {
            .content {
                padding: 15% 25% 0% 25%;
                margin-bottom: 0;
            }
            .logo {
                margin-left: 27%;
            }
        }
        
        @media (max-width: 550px) {
            .logo {
                width: 25%;
                margin-left: 19%;
                margin-bottom: 9%;
            }
        }
        
        @media (max-width:450px) {
            .assinatura {
                width: 70%;
            }
            .assinatura2 {
                width: 90%;
            }
        }
        
        @media (max-width: 390px) {
            .content {
                font-size: 12px;
                padding: 13% 16% 0% 16%;
            }
            .assinatura {
                width: 50%;
            }
            .assinatura2 {
                width: 70%;
            }
            .logo {
                margin-bottom: 10px;
            }
        }
        
        @media (max-width: 280px) {
            .content {
                font-size: 9px;
            }
        }