@import url("https://fonts.googleapis.com/css2?family=Mulish&family=Raleway&display=swap");

.container {
    background-image: url('../../../assets/agilita/backgorund_mascote.svg');
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: 100% 0%;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 10px 10px 10px 10px;
    .titulo {
        font-style: italic;
        font-size: 40px;
        margin-bottom: 15px;
        // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
        //         "Helvetica Neue", sans-serif;
        color: #f8f0e3;
    }
}
@media only screen and (max-width: 767px) {
    .container{
        background-image: url('../../../assets/agilita/backgorund_mascote.svg');
        background-size: cover;
        background-attachment:fixed;
        background-repeat:no-repeat;
        background-position: 100% 0% ;
    }
  }
  
%banner{
    width: 100%;
}

.banner_1{
    width: 360px;
}   
.banner_2{
    width: 200px;
    min-width: 250px;
}
.Spam{
color:red;
}

.logo {
    width: 180px;
}

.logoTitulo {
    width: 80%;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 40px;
    margin-top: 10px;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5vw;
}

.divAlan {
    height: 100%;
    width: 80%;
    background: linear-gradient(110deg, rgba(255, 255, 255, 0) 20%, #75ae4b 45%);
    box-shadow: inset -3px -0.5px 3px -1px rgb(0 0 0 / 20%);
    border-radius: 100px 100px 100px 100px;
    display: flex;
    align-items: flex-end;
    justify-content: end;
    padding-right: 60px;
}

.modalidade {
    margin-bottom: 10px;
    width: 80%;
    display: flex;
    height: 5vh;
    font-weight: bold;
    background: linear-gradient(90deg, #77AE4A 17.74%, #77AE4A 17.75%, rgba(0, 150, 150, 0.49) 53.36%, rgba(0, 150, 150, 0) 75.69%);
    font-size: 18px;
    color: #f8f0e3;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    p {
        margin: 5px 0px 5px 0px;
        font-family: 'Bebas Neue', cursive;
        
    }
}

.categoriaContainer {
    display: flex;
    width: 80%;
    justify-content: flex-start;
    align-items: center;
    gap:5px;
}

.categoria {
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
    // box-shadow: 5px 5px #a3aca5;
    // background-image: linear-gradient(to left, #77ae4a, #009696);
    // width: 40%;
    // min-width: 500px;
    // display: flex;
    // height: 10vh;
    // max-height: 80px;
    // font-weight: bold;
    // font-size: 18px;
    // color: #f8f0e3;
    // justify-content: space-between;
    // align-items: center;
    // padding: 20px 45px 20px 45px;
    // border-radius: 10px 30px 10px 30px;
    // border: 1px solid #77ae4a;
    width: 100%;
    color:#191d35;
    p {
       
        margin: 5px 0px 5px 0px;
        font-family: 'Bebas Neue', cursive;
        font-weight: bolder;
        font-size: 18px;
        margin-right: 5px;
       
    }
    h4{
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    }
}

.categoriaTitulo{
    display: flex;
    align-items: center;
}

.lista {
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    justify-content: center;
    flex-wrap: wrap;
    width: 80%;
    max-height: 250px;
    margin: 15px 0px 50px 0px;
  
}

.desenho {
    display: flex;
    flex-direction: column;
    margin: 5px;
    align-items: center;
    button {
        width: 100px;
        border: solid 1px #77ae4a;
        background-color: #77ae4a;
        margin-top: 10px;
    }
}

.lista::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */

.lista {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.contentControls {
    display: flex;
    flex-direction: row;
}

.divVotacao {
}

@media (max-width: 800px) {
    .modalidade {
        width: 90vw;
        height: 10vh;
        max-height: 100px;
    }
    .categoriaContainer{
        width: 90vw;
    }
    .categoria{
        width: 100%;
        min-width: 0;
    }
}

.visibleArrowProx {
    visibility: hidden;

}
.visibleArrowPrev {
    visibility: hidden;

}


@media (max-width: 600px) {
    .logo {
        width: 180px;
    }
    .logoTitulo{
        width: 90%;
        justify-content: center;
        gap: 25px;
    
    }
    .divAlan {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: end;
        padding-right: 50px;
        text-align: end;
    }
    .prox {
        display: flex;
        background-color: #77ae4a;
    }
    .modalidade {
        width: 90vw;
        height: 10vh;
        max-height: 100px;
        background: linear-gradient(90deg, #77AE4A 17.74%, #77AE4A 49.74%, rgba(0, 150, 150, 0.49) 75.69%);
    }
    .container {
        width: 100vw;
    }
    .categoria{
        height: 10vh;
        max-height: 100px;
    }
    .visibleArrowProx {
        visibility: visible;
        color: #a0a0a0;
        position: absolute;
        align-self: flex-end;
        font-size: 40px;
    }
    .visibleArrowPrev {
        position: absolute;
        color: #a0a0a0;
        font-size: 40px;
        visibility: visible;

    }
   
}

@media (max-width: 361px) {
    .modalidade {
        width: 90vw;
        height: 17vh;
        max-height: 150px;
    }
    .container {
        width: 100vw;
    }
    .categoria {
        height: 17vh;
        max-height: 150px;
    }
}

@media (max-width: 281px) {
    .modalidade {
        width: 90vw;
        height: 22vh;
        max-height: 170px;
    }
    .container {
        width: 100vw;
    }
    .categoria {
        height: 22vh;
        max-height: 170px;
    }
}



.noneArrow {
    display: none;
}

.ModalVoto {
}

.FormVoto {
    margin: 0 8% 0;
    height: 24vh;
    min-height: 240px;
    max-height: 800px;
}

.ButtonVotar {
    border: solid 1px #191d35 !important;
    background-color: #191d35 !important;
    margin-top: 10px;
    color: white !important;
}

.ButtonVotar:focus {
    border: solid 1px #191d35;
    background-color: #191d35;
    margin-top: 10px;
    color: white;
}

.ButtonVotar:hover {
    border: solid 1px #191d35 !important;
    background-color: #a7a8ac !important;
    margin-top: 10px;
    color: #191d35 !important;
}

.Buttons {
    text-align: center;
}

.FormName {
    margin-bottom: 5%;
}

.Inputs {
    width: 100%;
    max-width: 500px;
    padding: 0.75em 0.75em;
    background: #fff;
    border: 1px solid #009696;
    font-weight: 600;
    font-size: 1em;
    -moz-border-radius: 0.75em;
    -webkit-border-radius: 0.75em;
    border-radius: 0.75em;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -o-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
}

.placeholderPdf {
    height: 200px;
    width: 190px;
    background-color: #009696;
    display: flex;
    cursor: pointer;
    margin: 10px 20px 0 20px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    font-weight: bold;
    flex-direction: column;
}

.pdfContainer {
    height: 100%;
    max-height: 600px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 10px 10px;
    padding: 10px 10px;
}

.modalPdf {
    max-height: 800px;
    display: flex;
    justify-content: center;
    width: 100% !important;
}

.btnArea {
    display: flex;
    justify-content: flex-end;
}

@media (max-width: 500px) {
    .modalPdf {
        max-height: 800px;
        display: flex;
        justify-content: center;
        width: 400px;
    }
    .pdfContainer {
        max-height: 600px;
        overflow-y: scroll;
        overflow-x: visible;
        margin: 10px 10px;
        padding: 10px 10px;
        width: 300px;
    }
    .pagePdf {
        width: 700px;
    }
    .btnArea {
        width: 230%;
    }
}

.CardPdf {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.icone_sociedade{
    width: 80px;

}

.icone_estudante{
    width: 50px;
}
.iconArea{
    width: auto;
    padding: 0px;

}
.divider{
    width: 100%;
    height: 3px;
    background: linear-gradient(90deg, #77AE4A 17.74%, #77AE4A 49.74%, rgba(0, 150, 150, 0.49) 75.69%);

}
