.input {
    width: 60vw;
    max-width: 350px;
    margin-bottom: 0.5rem;
    padding: 0.75em 0.75em;
    background: #fff;
    border: 1px solid rgba(3, 142, 191, 0.81);
    font-weight: 600;
    font-size: 1em;
    -moz-border-radius: 0.75em;
    -webkit-border-radius: 0.75em;
    border-radius: 0.75em;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -o-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-align: center;
    border-radius: 1em;
    padding: 2%;
    margin-top: 0em;
    padding-top: 0;
    
}

.inputButton {
    color: white;
    display: flex;    
    margin-top: 0.7em;
    justify-content: center;
}

.confirmButton {
    
    background-color: rgba(3, 142, 191, 0.81);
    color: white;
    font-weight: 400;
    border: none;
    border-radius: 5px;
    text-align: center;
    transition: all 0.75s;
    cursor: pointer;    
}

.FormName {
    display: flex;
    flex-direction: column;
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 1vw;
    text-align: left;
}

.titulo {
    font-size: 2em;
    font-weight: bold;
    color: #009696;
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
}