.campo {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 38px;
}

.input {
    border-radius: 5px;
    border: 1px solid #038EBFCF;
    height: 30px;
    width: 45%;
    background: transparent;
    padding-left: 7px;
}

.img {
    height: 21px;
    padding-left: 13px;
    padding-top: 4px;
}

.img:hover {
    cursor: pointer;
}

@media (max-width: 690px) {
    .campo {
        margin-right: 90px;
    }
}