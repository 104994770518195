.bannerArea {
    display: flex;
    padding: 2%;
    justify-content: center;
    min-width: 100px;
    max-width: 1022px;
    width: 100%;
    min-height: 100px;
    max-height: 500px;
    height: 100%;
}

.bannerArea > img {
    aspect-ratio: 1022 / 268;
    min-width: 100px;
    max-width: 1022px;
    width: 100%;
    min-height: 50px;
    max-height: 268px;
    height: 100%;
}

@media only screen and (max-width: 700px) {
    // .bannerArea {
    //     display: none;
    // }
}