.container{
    
    padding-left: 15%;
    padding-right: 15%;
    
}
.carousel{
    width: 100%;
    display:flex;
    flex-direction: row;
    

}
button{
    background-color: rgba(127, 255, 212, 0);
    border:none;
}
.arrow{
    width: 10%;
    display:flex;
    align-items: center;
    justify-content: center;
    .icon{
        font-size:30px;
    }
    .icon:hover{
        color:white;
    }
}
.content{
    width: 100%;
    display:grid;
   grid-template-columns: 50% 50%;
   padding-left: 8%;
}
@media (max-width:700px){
    .arrow{
        display: none;
       
    }
    .content{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
}

// @keyframes stretch {
//     /* declare animation actions here */
//     0%{background-color: rgb(100, 237, 164);width:30%;}
//     30%{width: 60%;}
//     70%{width: 90%;}
//     100%{background-color: darkblue;}
// }

// .card{
//     background-color: darkblue;
//     height:280px;
//     margin:10px;
//     animation-name: stretch;
//     animation-duration: 2s; 
  
// }
