.Titulo {
    font-size: 2em;
    font-weight: bold;
    color: #009696;
    display: flex;
    justify-content: center;
    margin-bottom: 35px;
}

.Centro {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    font-size: 1.2em;
}