.header {
    width: 100%;
    height: 55px;
    display: grid;
    background-color: #004F9F;
    grid-template-columns: auto auto;
    align-items: center;
    z-index: 100;
}


.logo {
    height: 48px;
    margin-right: 85%;
    margin-left: 5%;
}

.links_full {
    display: flex;
    justify-content: center;
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    li {
        float: left;
    }
    li a {
        height: auto;
        display: block;
        color: white;
        text-align: center;
        padding: 16px;
        text-decoration: none;
    }
    li a:hover {
        background-color: #014282;
    }
}

.links_collapse {
    display: none;
    justify-content: center;
}

.links_dropdown {
    display: none;
}

@media (max-width: 700px) {
    .links_full {
        display: none;
    }
    .links_collapse {
        display: flex;
        justify-content: end;
        margin-right: 10%;
    }
    button {
        background-color: #004F9F;
        border: none;
        font-size: 20px;
        color: white;
    }
    .links_dropdown {
        z-index: 1000;
        position: fixed;
        top: 55px;
        background-color: #62a22a;
        width: 100%;
        height: 20vh;
        display: flex;
        flex-wrap: wrap;
        div {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 5px;
            color: white;
            justify-content: space-around;
            border-bottom: 0.5px solid rgba(0, 0, 0, 0.52);
            a {
                color: white;
            }
        }
    }
    .header{
        position: fixed;
    }
}

@media (max-width:450px) {
    .logo_area {
        .logo {
            margin-left: 25%;
        }
    }
}