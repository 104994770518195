.container {
    justify-content: center;
    display: flex;
}

.container button {
    background-color: rgba(3, 142, 191, 0.81);
    cursor: pointer;
    border: 1px solid rgba(3, 142, 191, 0.81);
    border-radius: 5px;
    color: white;
    width: 9%;
    height: 20px;
    margin: 10px 7px;
    font-size: 60%;
}

@media (max-width: 750px) {
    .container button {
        font-size: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 17%;
        box-sizing: border-box;
    }
}