/*Styles ModalEdital*/
.modal {
    min-width: 650px;
    width: 100% !important;
    max-width: 800px;
    min-height: 700px;
    height: 700px;
    max-height: 800px;
}

.pdfContainer {
    height: 100%;
    max-height: 600px;
    overflow: scroll;
}

.buttonsAceite {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    padding-right: 5px;
    padding-left: 5px;
}
/*Fim Styles ModalEdital*/

.buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1%;
}

.buttonAbrir {
    min-width: 100px;
    max-width: 300px;
    width: 100%;
    height: 40px;
    border: 1px solid rgb(3, 142, 191, 80%);
    background-color: rgb(255, 255, 255);
    color: rgb(3, 142, 191, 80%);
    font-size: 13px;
    font-weight: bold;
    text-overflow: ellipsis;
    padding: 8px;
    cursor: pointer;
}

.buttonAbrir:hover {
    border: 1px solid rgba(3, 179, 243, 0.8);
    background-color: rgb(3, 142, 191, 80%);
    color: rgb(255, 255, 255);
}

.buttonAberto {
    min-width: 100px;
    max-width: 300px;
    width: 100%;
    height: 40px;
    border: 1px solid rgb(3, 142, 191, 80%);
    background-color: rgb(3, 142, 191, 100%);
    color: rgb(255, 255, 255);
    font-size: 13px;
    font-weight: bold;
    text-overflow: ellipsis;
    padding: 8px;
    cursor: pointer;
}

@media only screen and (max-width: 700px) {
    .buttonAbrir {
        font-size: 12px;
        padding: 3px;
    }
    .buttons {
        margin-top: 2%;
    }
    .buttonAberto{
        font-size: 12px;
        padding: 3px;
    }
}

@media (max-width: 450px) {
    .buttonAbrir {
        font-size: 11px;
        padding: 3px;
    }
    .buttons {
        margin-top: 3%;
    }
    .buttonAberto{
        font-size: 11px;
        padding: 3px;
    }
}
