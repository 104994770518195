.spinner {
    height: 35px;
    width: 35px;
    border: 8px solid rgba(0, 0, 0, 0.4);
    border-left-color: #22a6b3;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    left: 50%;
    top: 50%;
    z-index: 1000;
    position: fixed;
}

.viewPortOverlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(150, 150, 150, 0.3);
    z-index: 1000;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

@media (max-width: 600px) {
    .spinner {
        width: 26px;
        height: 26px;
    }
}