.card {
    display: grid;
    grid-template-columns: 1fr 70%;
    width: 70%;
    height: 200px;
    box-sizing: border-box;
    margin-left: 100px;
}

.info {
    border: 2px solid rgba(0, 150, 150, 0.8);
    display: grid;
    background-color: white;
    grid-template-rows: 50% 50%;
    height: 191px;
    margin-top: 10px;
    padding: 18px;
    margin-left: -60px;
}

.top {
    padding: 15px;
}

.bottom {
    padding: 10px;
}

.info label {
    width: 150px;
    height: 150px;
    padding: 50px;
}

.leftcard {
    box-sizing: border-box;
    width: max-content;
    
}