
// $background-color:#009696;
@keyframes stretch {
    /* declare animation actions here */
    0%{background-color: rgb(100, 237, 164);width:20%;}
    30%{width: 40%;}
    50%{width: 60%;}
    70%{width: 80%;}
    100%{background-color: rgb(0, 150, 150,80%);}
}
.card {
    display: grid;
    width: 80%;
    grid-template-rows: 70% 30%;
    height: 280px;
    box-shadow: 9px 9px 4px 1px rgb(0, 150, 150,50%);
    background-color: rgb(0, 150, 150,80%);
    margin:10px;
    animation-name: stretch;
    animation-duration: 0.1s; 
}

.txt {
    text-align: left;
    padding-top: 5px;
    padding-left: 5px;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
}

.img {
    width: 80%;
    max-height:196px;
    // height: 100%;
}

.img:hover {
    cursor: pointer;
}

.bottom {
    width: 100%;
    background-color: rgb(0, 150, 150,80%);
    padding-top: 5px;
    // border: 1px solid rgb(0, 150, 150,80%);
}

.desc {
    box-sizing: border-box;
    word-break: break-all;
}

.btn {
    border: none;
    background: rgb(239, 239, 239);
}