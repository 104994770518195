:global {
    .tabcontent-component_content__S5bt7 {
        padding: 0;
    }

    .ant-form-vertical .ant-form-item-label,
    .ant-col-24.ant-form-item-label,
    .ant-col-xl-24.ant-form-item-label {
        padding-bottom: 1px !important;
    }

    .ant-tabs-tab {
        cursor: no-drop !important;
    }

    .ant-tabs-tab:hover {
        color : black;
        cursor: no-drop !important;

    }

    @media (max-width:415px) {
        .ant-tabs-tab {
            margin-left    : 10px !important;
            margin-right   : 10px !important;
            justify-content: center !important;
        }

        .ant-tabs-tabpane {
            width: 20em !important;
        }


        .ant-tabs-content {
            display        : flex;
            justify-content: center;
            padding        : 5px;
        }

        .ant-form-item .ant-form-item-label>label {
            margin-top: 2px;
        }
    }

}
@select-border-color: red;@select-dropdown-bg: blue;@input-disabled-color: black;