.buttonArea {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: 3%;
}

.mensagemAceite {
    min-width: 100px;
    max-width: 300px;
    width: 100%;
    font-style: italic;
    color: red;
}

.mensagemForaPeriodo{
    margin-top: 10px;
    text-align: center;
    min-width: 100px;
    max-width: 300px;
    width: 100%;
    font-style: italic;
    font-size: 1.5em;
    color: red;
}

.inscricaoButton {
    min-width: 100px;
    max-width: 300px;
    width: 100%;
    height: 60px;
    background-color: rgb(3, 142, 191, 80%);
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-overflow: ellipsis;
    overflow: hidden !important;
    padding: 0px;
    cursor: pointer;
}

.inscricaoButton:hover {
    background-color: rgba(8, 113, 151, 0.8);
    border: 1px solid rgba(6, 154, 207, 0.8);
}

.inscricaoButton:disabled {
    background-color: rgba(172, 170, 170, 0.582);
    border: 1px solid rgba(146, 146, 146, 0.8);
    cursor: context-menu;
}

.inscricaoButton:disabled:hover {
    cursor: not-allowed;
}

.queroInscreverButton {
    z-index: 999999;
    min-width: 100px;
    max-width: 300px;
    width: 100%;
    height: 60px;
    background-color: rgba(0, 187, 255, 1);
    color: rgb(255, 255, 255);
    font-size: 25px;
    text-overflow: ellipsis;
    overflow: hidden !important;
    padding: 8px;
    cursor: pointer;
}

.queroInscreverButton:hover {
    background-color: rgba(8, 113, 151, 1);
    border: 1px solid rgba(6, 154, 207, 1);
}

.queroInscreverButton:disabled {
    background-color: rgba(172, 170, 170, 0.582);
    border: 1px solid rgba(146, 146, 146, 0.8);
    cursor: context-menu;
}

.queroInscreverButton:disabled:hover {
    cursor: not-allowed;
}

.box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.actionsArea {
    background-color: white;
    display: flex;
    width: 17%;
    max-width: 300px;
    min-width: 210px;
    max-height: 200px;
    min-height: 150px;
    flex-direction: column;
    align-items: center;
    color: rgb(3, 142, 191, 100%);
    font-size: 15px;
    padding: 10px;
    justify-content: center;
}

.actionButton {
    font-size: 15px;
    color: white;
    width: 90%;
    height: 20%;
    background-color: rgba(3, 142, 191, 0.8);
    margin-top: 10px;
    cursor: pointer;
}

.buttonClose {
    align-self: flex-end;
    font-size: 20px;
    background-color: white;
    margin-right: 10px;
    color: rgb(3, 142, 191, 100%);
    cursor: pointer;
    margin-right: 10px;
    margin-top: -15px;
}

@media only screen and (max-width: 700px) {
    .inscricaoButton {
        font-size: 20px;
    }
    .queroInscreverButton {
        font-size: 20px;
    }
    .actionsArea {}
}

@media (max-width: 1000px) {
    .actionsArea {}
}

@media (max-width: 450px) {
    .inscricaoButton {
        font-size: 15px;
    }
    .queroInscreverButton {
        font-size: 15px;
    }
    .actionsArea {}
}