.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.formSignin {
    text-align: center;
    align-items: center;
    border-radius: 1em;
    padding: 2%;
    margin-top: 0em;
    padding-top: 0;
}

.esqueciSenha>a {
    color: rgba(3, 142, 191, 0.81);
    display:flex;
    flex-direction: column;
}

.logo {
    width: 40%;
    max-width: 500px;
    height: 40%;
    max-height: 400px;
    margin: 1em;
}

.inputContainer {
    width: 100%;
    padding: 0.5em;
    margin-bottom: 0;
}

.titulo {
    font-size: 2em;
    font-weight: bold;
    color: #009696;
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
}

.ant-form-item {
    margin-bottom: 20px !important;
}

.input {
    width: 60vw;
    max-width: 350px;
    margin-bottom: 0.5rem;
    padding: 0.75em 0.75em;
    background: #fff;
    border: 1px solid rgba(3, 142, 191, 0.81);
    font-weight: 600;
    font-size: 1em;
    -moz-border-radius: 0.75em;
    -webkit-border-radius: 0.75em;
    border-radius: 0.75em;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -o-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
}

.input::placeholder {
    color: #b1b1b1;
}

.input>input[type="password"]::placeholder {
    color: #b1b1b1;
    font-weight: bold;
}

input:active,
input:focus,
input:visited,
input:hover {
    outline: none;
}

.confirmButton {
    background-color: rgba(3, 142, 191, 0.81);
    color: white;
    font-weight: 400;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 50%;
    height: 3.5em;
    margin: 5px;
}

.inputButton {
    color: white;    
 
    margin-top: 0.7em;
}

.inputButton :hover {
    color: white;
}

.login>.formulario {
    width: 375px;
    margin: 0 3rem;
    height: 28.5em;
    margin-top: 0em;
}

@media only screen and (max-width:1360px) {
    .titulo {
        margin-bottom: 0.5em;
    }
}

@media only screen and (max-width: 588px) {
    .logos-grid {
        display: none;
    }
}

.form-button {
    width: 300px;
    display: inline-flex;
}