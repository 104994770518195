.concursoPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 40px;
}

.tituloConcurso {
    width: 100%;
    display: flex;
    justify-content: center;
}

.tituloConcurso > h1 {
    color: rgb(0, 150, 150, 80%);
    font-weight: 700, Bold;
    line-height: 33px;
    font-size: 33px;
    text-align: center;
    font-weight: bold;
}

.info {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: rgb(0, 150, 150, 80%);
    font-size: 20px;
    margin-bottom: 5%;
}

.modal {
    padding-top: 10%;
}

.contentModal {
    margin: 5%;
    text-align: center;
}

.iconModal {
    text-align: center;
    font-size: 2.5em;
    color: rgba(0, 187, 255, 0.8);
}

.buttonFooter {
    background-color: rgba(0, 187, 255, 0.8);
    border-color: rgba(0, 187, 255, 0.8)
}

.buttonFooter:hover {
    background-color: rgba(8, 113, 151, 0.8);
    border: 1px solid rgba(6, 154, 207, 0.8);
}

@media only screen and (max-width: 500px), 
screen and (max-height: 800px) {
    .tituloConcurso > h1 {
        line-height: 24px;
        font-size: 20px;
        font-weight: bold;
    }

    .info {
        font-size: 16px;
        margin-bottom: 10px;
    }
}
