@use "theme";
@import url("https://fonts.googleapis.com/css2?family=Mulish&family=Raleway&display=swap");

%containerDiv {
    display: flex;
    min-height: 100vh;
}
div.ant-select-selector {
    background-color: #f50c5b;
}
* {
    margin: 0;
    padding: 0;
    font-family: "Mulish", "Roboto", sans-serif;

    :focus {
        outline: none;
    }
    :disabled {
        color: inherit;
        opacity: 0.7;
        cursor: default;
    }
}
.globalStyle {
    display: grid;
    grid-template-rows: 55px repeat(auto-fit, minmax(calc(100vh - 115px), auto)) 60px;
}

.pageContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

body {
    height: auto;
    min-height: 100%;
    background: url("../assets/backgroundImage.jpg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: -webkit-fill-available;
}
// #root {
//     height: 100vh;
// }

::-webkit-scrollbar {
    width: 5px;
}

.headerSombra {
    height: 0;
    display: none;
}

@media (max-width: 700px) {
    .headerSombra {
        height: 55px;
        display: grid;
    }
}
